
import React from "react"
import Type from "../../components/typography/Type"


const ObjectCard = (props) => {	
	
const title = props.title; 	
const url = props.url;
const type = props.type;		
	
	return (
		
		<div>
			<a href={url}>
				<button className="bg-white hover:bg-white/50 rounded-lg py-4 px-auto w-full">
					<Type type="p4" nopadding="yes">{title}</Type></button>
			</a>
		</div>
		
	);
};

export default ObjectCard;
