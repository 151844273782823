import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import Layout from "../../components/layout/Layout"
import Button from "../../components/controls/Button"
import ObjectCard from "../../components/cards/ObjectCard"


import { useState } from "react"



export default function Pages( {data}) {


return (
  <>
  <Layout>
  <Container>
  {isLoggedIn() ? (

 <div >
 
    <Type type="p2">{data.airtable.data.Name}</Type>

 <Type type="h2">Frameworks</Type>
 <div className="flex flex-col space-y-8">

   <div  className="bg-blue p-8">
     <Type type="h3">Blueprints</Type>
    <div className="grid grid-cols-3 gap-12 text-black"> 
    {data.airtable.data.BlueprintsL1
      ?
      <>
     {data.airtable.data.BlueprintsL1.map((BlueprintsL1Array) => (
       <ObjectCard url={BlueprintsL1Array.data.Path} title={BlueprintsL1Array.data.Name}/>
    
     ))}
<Button type="addObject" title="Add another Blueprint" action="/new/blueprints"/>
     </>
      :
      <Button type="addObject" title="Create your first Blueprint" action="/new/blueprints"/>

      
   }
    </div>
          
   </div> 
   
   
   <div  className="bg-blue p-8">
      <Type type="h3">Journeys</Type>
      <div className="grid grid-cols-3 gap-12 text-black">  
     {data.airtable.data.Journeys
       ?
       <>
       {data.airtable.data.Journeys.map((JourneysArray) => (
        <a href={JourneysArray.data.Path}><div className="rounded-lg bg-white hover:bg-blue border border-blue hover:border-white p-4"><Type type="p4" nopadding="yes">{JourneysArray.data.Name}</Type></div></a>
         ))}
         <Button type="addObject" title="Add another Journey" action="/new/journeys"/>
</>         
       : 
       <Button type="addObject" title="Create your first Journey" action="/new/journeys"/>    
     }
     

     </div>
      
     
    </div> 
 
     <div className="bg-blue p-8">
     <Type type="h3">Horizon Maps</Type>
     <div className="grid grid-cols-3 gap-12 text-black"> 
     {data.airtable.data.Roadmaps
       ?
       <>
     {data.airtable.data.Roadmaps.map((RoadmapsArray) => (
     <a href={RoadmapsArray.data.Path}><div className="rounded-lg bg-white hover:bg-blue/80 border border-blue/80 hover:border-white p-4"><Type type="p4" nopadding="yes">{RoadmapsArray.data.Name}</Type></div></a>
      ))}
      <Button type="addObject" title="Add another Horizon Map" action="/new/horizonmaps"/>

     </>
      :
     <Button type="addObject" title="Create your first Horizon Map" action="/new/horizonmaps"/>
    }
     </div>
     </div> 
 
 <div className="bg-blue p-8">
 <Type type="h3">Product Story Maps</Type>
 <div className="grid grid-cols-3 gap-12 text-black"> 
 {data.airtable.data.ProductStories
   ?
   <>
  {data.airtable.data.ProductStories.map((ProductStoriesArray) => (
  <a href={ProductStoriesArray.data.Path}><div className="rounded-lg bg-white hover:bg-blue/60 border border-blue/60 hover:border-white p-4"><Type type="p4" nopadding="yes">{ProductStoriesArray.data.Name}</Type></div></a>
   ))}
   <Button type="addObject" title="Add another Product Story Map" action="/new/productstorymap"/>
 </>
   :
   <Button type="addObject" title="Create your first Product Story Map" action="/new/productstorymap"/>
 }
 </div>
  </div> 
 
 <div className="bg-blue p-8">
 
 <Type type="h3">Project Maps</Type>
 
<div className="grid grid-cols-3 gap-12 text-black"> 
<a href={data.airtable.data.CapabilitiesPath}><div className="rounded-lg bg-white hover:bg-blue/60 border border-blue/60 hover:border-white p-4"><Type type="p4" nopadding="yes">Capabilities</Type></div></a>
 <a href={data.airtable.data.NeedsPath}><div className="rounded-lg bg-white hover:bg-blue border border-blue hover:border-white p-4"><Type type="p4" nopadding="yes">Needs</Type></div></a>
 <a href={data.airtable.data.ActorsPath}><div className="rounded-lg bg-white hover:bg-blue border border-blue hover:border-white p-4"><Type type="p4" nopadding="yes">Actors</Type></div></a>
  </div>
 </div>  
 
 <div className="bg-blue p-8">
  <Type type="h3">Value Stream Maps</Type>
<div className="grid grid-cols-3 gap-12 text-black"> 
{data.airtable.data.ValueStreams
  ?
  <>
  {data.airtable.data.ValueStreams.map((ProcessesL1Array) => (
  <a href={ProcessesL1Array.data.Path}><div className="rounded-lg bg-white hover:bg-blue/60 border border-blue/60 hover:border-white p-4"><Type type="p4" nopadding="yes">{ProcessesL1Array.data.Name}</Type></div></a>
  ))}
  <Button type="addObject" title="Add another Value Stream Map" action="/new/valuestreams"/>

</>
:
  <Button type="addObject" title="Create your first Value Stream Map" action="/new/valuestreams"/>

}
</div>
  </div>
 </div>
 </div>

 
    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </Container>
  </Layout>
    </>

  )
}

export const Head = () => <title>Framework</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Projects" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        NeedsPath
        CapabilitiesPath
        ActorsPath
        BlueprintsL1
        {
          data {
            Name
            Path
            
          }
        }
        ValueStreams
        {
          data {
            Name
            Path
          }
        }
        Moments
        {
          data {
            Name
            Path
          }
        }
        Roadmaps
        {
          data {
            Name
            Path
          }
        }
        ProductStories
        {
          data {
            Name
            Path
          }
        }
        Journeys
        {
          data {
            Name
            Path
          }
        }
      }
    }
  }
`
